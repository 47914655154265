<template>
  <a-row type="flex" align="middle" :gutter="8" wrap="wrap">
    <a-col><small>{{ $t('logistics.payment_countdown') }}: </small></a-col>
    <a-col>
      <a-statistic-countdown v-if="value > 0" :value="getCountdownValue()" :valueStyle="valueStyle" @finish="handleFinish" />
      <span v-else>-</span>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent } from 'vue';
import { Col, Row, Statistic } from 'ant-design-vue';

export default defineComponent({
  emits: ["finish"],
  components: {
    ARow: Row,
    ACol: Col,
    AStatisticCountdown: Statistic.Countdown,
  },
  props: {
    value: {
      type: [Number, String],
      default: 0
    },
    valueStyle: {
      type: String,
      default: "font-size: 12px; color: #f50;"
    }
  },
  setup (props, { emit }) {
    const getCountdownValue = () => {
      return Date.now() + 1000 * props.value;
    }

    const handleFinish = () => {
      emit("finish");
    }

    return {
      getCountdownValue,
      handleFinish,
    }
  }
})
</script>

<style scoped>

</style>