<template>
  <!-- 追踪号modal -->
  <a-modal
    width="700px"
    v-model:visible="isShowModal"
    :title="$t('warehouse.tracking_shipment')"
    :mask-closable="false"
  >
  <a-table
    :columns="columns"
    :data-source="modalList"
    :pagination="false"
    :expandIconAsCell="false"
    :expandIconColumnIndex="2"
    :row-key="(record) => record.planDetailId"
  >
    <template #productNameAndSeSku>
      {{ $t("warehouse.product_name") }}/{{ $t("warehouse.product_no") }}
    </template>
    <template #serialNumber="{ index }">
      {{ index + 1 }}
    </template>
    <template #boxNo="{ record }">
      <div>{{ record.boxNo || '-' }}</div>
      <div><small>{{ record.selfBoxNo || '-' }}</small></div>
    </template>
    <template #expandIcon="{ expanded, record, onExpand }">
      <CHaveProductInformation :open="expanded" @click="e => onExpand(record, e)" />
    </template>
    <template #logisticTrackNo="{ record }">
      {{ record.logisticTrackNo || '-' }}
    </template>
    <template #expandedRowRender="{ record }">
      <a-table
        :columns="innerColumns"
        :data-source="record.productInfos"
        :pagination="false"
        :row-key="(record) => record.id"
        size="small"
      >
        <template #productInfo="{ record }">
          <a-row :gutter="8" type="flex" align="middle">
            <a-col>
              <div class="table-list-img-common">
                <c-image
                  :src="record.productImgUrl"
                  :thumbWidth="600"
                  :thumbHeight="600"
                />
              </div>
            </a-col>
            <a-col flex="1">
              <div>{{ record.productName }}</div>
              <div>{{ record.productNo }}</div>
            </a-col>
          </a-row>
        </template>
        <template #containerCount="{ record }">
          {{ record.containerCount }} Unit
        </template>
      </a-table>
    </template>
  </a-table>
    <template #footer>
      <a-button type="ghost" @click="handleClose">{{ $t('common.close') }}</a-button>
    </template>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import { Table, Input, Button, Modal, Row, Col } from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import CHaveProductInformation from "@/views/components/CHaveProductInformation.vue";
import CImage from "@/views/components/CImage.vue";

export default defineComponent({
  name: "TraceModal",
  components: {
    ATable: Table,
    AInput: Input,
    AButton: Button,
    AModal: Modal,
    ARow: Row,
    ACol: Col,
    CHaveProductInformation,
    CImage,
  },
  setup() {
    const i18n = useI18n({ useScope: "global" });

    const state = reactive({
      isShowModal: false,
      modalList: [],
    });

    const columns = [
      {
        width: 50,
        title: () => i18n.t("warehouse.serial_number"),
        slots: {
          customRender: "serialNumber",
        },
      },
      {
        width: 100,
        title: () => i18n.t("warehouse.box_no"),
        slots: {
          customRender: 'boxNo'
        }
      },
      { width: 50, },
      {
        width: 150,
        title: () => i18n.t("warehouse.tracking_no"),
        slots: {
          customRender: 'logisticTrackNo'
        }
      },
    ];

    const innerColumns = [
      {
        width: 350,
        title: () => i18n.t("warehouse.product_info"),
        slots: {
          customRender: "productInfo"
        }
      },
      {
        dataIndex: "seSku",
        width: 150,
        title: "SESKU",
      },
      {
        title: () => i18n.t("logistics.count"),
        slots: {
          customRender: "containerCount"
        }
      },
    ];

    const handleClose = () => {
      state.isShowModal = false
      state.modalList = []
    }

    const handleTrackShowModal = (list) => {
      state.modalList = list
      state.isShowModal = true;
    };

    return {
      ...toRefs(state),
      columns,
      innerColumns,
      handleTrackShowModal,
      handleClose,
    };
  },
});
</script>
